<template>
  <div v-if="item.name" class="accordion">
    <div @click="toggleAccordion">
      <h5>{{ item.name }}</h5>
      <svg
        :class="{ 'accordion-icon': true, expanded: item.expanded }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        id="angle-right"
      >
        <path
          fill="#2F2F2F"
          d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"
        ></path>
      </svg>
    </div>
    <div v-show="item.expanded" class="items">
      <div v-for="el in item.items" :key="el.name">
        <div @click="emitRouted">
          <router-link :to="el.link">
            {{ el.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="accordion">
    <div @click="emitRouted">
      <router-link :to="item.items[0].link" class="nav__link">
        {{ item.items[0].name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    toggleAccordion() {
      this.item.expanded = !this.item.expanded;
    },
    emitRouted() {
      this.$emit('routed');
    },
  },
};
</script>

<style lang="scss">
.accordion {
  border-bottom: solid 1px var(--border-color);
  & > div:not(.items) {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav__link {
    font-size: 1rem;
    font-weight: 700;
  }
  .items {
    display: flex;
    padding-bottom: 1rem;
    flex-flow: column;
    text-align: left;
    gap: 0.5rem;
  }
}
.accordion-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  transition: transform 0.3s;
}

.expanded {
  transform: rotate(90deg);
}
</style>
