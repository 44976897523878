<template>
  <div class="container-main">
    <div class="section">
      <h1>Conocenos</h1>
      <p>
        ELHA es una empresa digital para la gestión de recursos humanos. Nuestro
        propósito es, a través del uso de la tecnología, conectar los objetivos
        de las empresas con TODOS los trabajadores de la organización,
        fortaleciendo la comunicación y el clima laboral.
      </p>
    </div>
    <hr class="separator" />
    <div class="section meaning">
      <div>
        <h2>ELHA - SOLUCIONES DIGITALES EN RECURSOS HUMANOS</h2>
        <p>
          <span style="margin-left: 20px; color: inherit">ELHA</span>
          significa inicio, crecimiento, aprendizaje, desarrollo, oportunidad,
          unión, esperanza y fe. Se desarrolla bajo el concepto del ciclo de
          vida que nos regala la naturaleza como un sentido para mejorar, a
          través de nuestro trabajo, el mundo. <br /><br />
          <span style="margin-left: 20px; color: inherit">Nuestra</span>
          filosofía se basa en la igualdad y en la democratización de
          oportunidades a través del uso de la tecnología, para nuestros
          clientes; desarrollando vínculos a largo plazo y crecimiento
          constante. <br /><br />
          <span style="margin-left: 20px; color: inherit">Queremos</span>
          innovar y desarrollamos plataformas digitales que permitan mejorar la
          gestión de recursos humanos en todo tipo y tamaño de empresas, así
          como también con instituciones del estado, mejorando la productividad
          y eficiencia en sus procesos. <br /><br />
          <span style="margin-left: 20px; color: inherit">Mejoramos</span>
          constantemente, lo que nos permite no tener miedo al error, de
          aprender de manera sostenida con la confianza de saber que estamos
          haciendo que las cosas sucedan, gestionando el cambio, sin perder el
          foco de nuestro trabajo. <br /><br />
          <span style="margin-left: 20px; color: inherit">En</span> ELHA,
          trabajamos todos los días con el propósito de brindar a todos nuestros
          stakeholder la mejor calidad de servicio.
        </p>
      </div>
      <div>
        <div>
          <h3>+40mil</h3>
          <p>
            Usuarios han realizado su encuesta de clima laboral con nosotros
          </p>
        </div>
        <div>
          <h3>+20</h3>
          <p>Empresas confían en nosotros</p>
        </div>
        <div>
          <h3>+5</h3>
          <p>Presencia en países a nivel Latam</p>
        </div>
        <div>
          <h3>3</h3>
          <p>Aliados estratégicos en diferentes paises.</p>
        </div>
      </div>
    </div>
    <hr class="separator" />
    <div class="section">
      <span>
        <figure>
          <img src="../assets/mision.jpg" alt="" />
          <div></div>
        </figure>
      </span>
      <div>
        <h2>
          MISIÓN &nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M12,5a7,7,0,1,0,7,7A7,7,0,0,0,12,5Zm0,12a5,5,0,1,1,5-5A5,5,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13ZM12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
            />
          </svg>
        </h2>
        <p>
          Unir las estrategias organizacionales con los procesos de recursos
          humanos a través de la gestión de personas
        </p>
      </div>
    </div>
    <div class="section">
      <div>
        <h2>
          VISIÓN &nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"
            />
          </svg>
        </h2>
        <p>
          En el 2024, conectar a 200 empresas con sus trabajadores,
          fortaleciendo los objetivos organizacionales
        </p>
      </div>
      <span>
        <figure>
          <img src="../assets/vision.jpg" alt="" />
          <div></div>
        </figure>
      </span>
    </div>
    <hr class="separator" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-main {
  margin-top: 120px;
}

.section:first-child {
  display: flex;
  flex-flow: column;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.section:not(:first-child) {
  margin-top: 120px;
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  svg {
    width: 1.8rem;
    fill: var(--text-color);
  }
  span {
    max-width: 900px;
    width: 51%;
  }
}

.section.meaning {
  display: flex;
  & > div:first-child {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    width: 50%;
    p {
      text-align: justify;
      text-justify: inter-word;
    }
  }
  & > div:nth-child(2) {
    text-align: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, auto);
    div {
      background-color: #f9f5f1;
      border-radius: 10px;
      padding: 1rem;
      max-width: 15rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      gap: 0.25rem;
    }
  }
}

.section > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 42%;
}

figure {
  height: 300px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    opacity: 0.7;
  }
  position: relative;
  div {
    position: absolute;
    height: 300px;
    width: 98%;
    border-radius: 10px;
    background-color: var(--primary-color);
    z-index: -1;
    top: -20px;
    right: -20px;
  }
}

@media (min-width: 1000px) {
}

@media (min-width: 766px) and (max-width: 999px) {
  .section.meaning {
    flex-flow: column;
    gap: 2rem;
    & > div:first-child {
      width: 100%;
    }
    & > div:nth-child(2) {
      width: 100%;
      grid-template-columns: repeat(4, auto);
    }
  }
}
@media (min-width: 640px) and (max-width: 765px) {
  p {
    font-size: 0.875rem;
  }
  .section.meaning {
    flex-flow: column;
    gap: 2rem;
    & > div:first-child {
      width: 100%;
    }
    & > div:nth-child(2) {
      width: 100%;
      grid-template-columns: repeat(4, auto);
    }
  }
}
@media (max-width: 639px) {
  h2 {
    display: flex;
    align-items: center;
  }
  .container-main {
    margin-top: 50px;
  }
  p {
    font-size: 0.875rem;
  }
  .section.meaning {
    flex-flow: column;
    gap: 2rem;
    & > div:first-child {
      width: 100%;
    }
    & > div:nth-child(2) {
      width: 100%;
      grid-template-columns: repeat(2, auto);
    }
  }

  .separator {
    margin-top: 50px;
  }
  .section:not(:first-child) {
    margin-top: 50px;
    flex-flow: column;
    gap: 3rem;
    &:nth-child(5) {
      flex-flow: column-reverse;
    }
    &:not(:first-child) span {
      width: 100%;
      figure {
        height: 200px;
        div {
          height: 200px;
        }
      }
    }
    & > div {
      width: 100%;
      gap: 0.5rem;
    }
  }
}
</style>
