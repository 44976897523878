<template>
  <div class="container">
    <div
      class="nav__container"
      :class="{ isWhite: isNavWhite }"
      v-on-scroll="handleScroll"
    >
      <div class="nav">
        <div>
          <a href="/#/inicio">
            <figure><img src="../assets/logos/elha-logo.svg" alt="" /></figure>
          </a>
        </div>
        <div class="nav__routes">
          <ul class="nav__list">
            <li class="nav__item" @click="hide = true">
              <router-link to="/inicio" class="nav__link">Inicio</router-link>
            </li>
            <li class="nav__item" @click="hide = true">
              <Menu direction="below" type="table">
                <template #label>
                  <span class="nav__link">Soluciones</span>
                </template>
                <template #options>
                  <div class="menu__content">
                    <div>
                      <p>
                        <router-link to="/clima-laboral">
                          <p>Clima laboral</p>
                          <p>Medición de clima laboral</p>
                        </router-link>
                      </p>
                    </div>
                    <div>
                      <p>
                        <router-link to="/evaluacion">
                          <p>Valoración de Puestos</p>
                          <p>Analiza cada puesto en tu organización</p>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </template>
              </Menu>
            </li>
            <li class="nav__item" @click="hide = true">
              <router-link to="/nosotros" class="nav__link">
                Nosotros
              </router-link>
            </li>
            <li class="nav__item" @click="hide = true">
              <Button variant="primary" size="md" to="/contactanos">
                Contactanos
              </Button>
            </li>
          </ul>
        </div>
        <div class="nav__burger">
          <svg
            v-if="!isNavOpen"
            @click="isNavOpen = true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            id="bars"
          >
            <path
              fill="#2F2F2F"
              d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
            ></path>
          </svg>
          <svg
            v-else
            @click="isNavOpen = false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            id="times"
          >
            <path
              fill="#2F2F2F"
              d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>

    <div class="mobile__nav" :class="{ open: isNavOpen }">
      <div>
        <Accordion
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          @routed="isNavOpen = false"
        ></Accordion>
        <div @click="isNavOpen = false">
          <Button variant="primary" size="md" to="/contactanos">
            Contactanos
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
import Button from '@/components/Button.vue';
import Menu from '@/components/Menu.vue';
export default {
  components: {
    Button,
    Menu,
    Accordion,
  },
  data() {
    return {
      isNavOpen: false,
      items: [
        {
          items: [{ name: 'Inicio', link: '/inicio' }],
          name: '',
          expanded: false,
        },
        {
          items: [{ name: 'Clima laboral', link: '/clima-laboral' }, { name: 'Valoración de Puestos', link: '/evaluacion' }],
          name: 'Soluciones',
          expanded: false,
        },
        {
          items: [{ name: 'Nosotros', link: '/nosotros' }],
          name: '',
          expanded: false,
        },
      ],
    };
  },
  methods: {
    handleScroll(evt, el) {
      if (window.scrollY > 60) {
        const classValue = `nav__container isScrolled ${
          this.isNavWhite ? 'isWhite' : ''
        }`;
        el.setAttribute('class', classValue);
      } else {
        const classValue = `nav__container ${this.isNavWhite ? 'isWhite' : ''}`;
        el.setAttribute('class', classValue);
      }
    },
  },
  computed: {
    isNavWhite() {
      return ['About', 'ContactUs'].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 80px;
  height: 80px;
}
.nav__burger {
  display: none;
}
.nav__container {
  height: 80px;
  width: calc(100vw - (100vw - 100%));
  background-color: #fffefc;
  left: 0;
  position: fixed;
  transition: 250ms height;
  &.isWhite {
    background-color: #fffefc;
    .nav__list .nav__link {
      color: var(--text-color);
    }
  }
  &.isScrolled {
    height: 60px;
    border-bottom: solid 1px var(--border-color);
    background-color: #fffefc;
    .nav__list .nav__link {
      color: var(--text-color);
    }
  }
  .nav__list {
    .nav__item {
      color: white;
    }
    .nav__link {
      color: inherit;
    }
  }
  background-color: transparent;
  z-index: 10000;
}
.nav {
  display: flex;
  align-items: center;
  height: 100%;
  width: 88vw;
  max-width: 1392px;
  margin: auto;
  justify-content: space-between;
  z-index: 10000;
  figure {
    height: 100%;
    width: 80px;
    display: grid;
    place-items: center;
  }
}
.nav__list {
  display: flex;
  align-items: center;
  gap: 0.5875rem;

  li {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .nav__link {
    min-height: 30px;
    font-size: 0.9375rem;
    font-weight: 544;
    color: var(--text-color);
    padding: 0.25rem 0.6875rem;
    border-radius: 5px;
    &:hover {
      background-color: #f7f6f4;
      color: var(--text-color);
    }
  }
}

.menu__content {
  text-align: left;
  div {
    padding: 0.2rem 0.5rem;
    a {
      height: fit-content;
    }
    &:hover {
      background-color: #f7f6f4;
    }
    p:first-child {
      font-size: 0.9375rem;
      font-weight: 500;
      color: var(--text-color);
    }
    p:nth-child(2) {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.mobile__nav {
  height: 100vh;
  width: 100vw;
  display: none;
  background-color: white;
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 9999;
  overflow-y: hidden; /* Enable vertical scrolling within the menu */
  overflow-x: hidden; /* Hide horizontal scrolling within the menu */
  & > div {
    height: 100vh;
    width: 88%;
    margin: auto;
    .btn {
      width: 100%;
      margin-top: 1.625rem;
    }
  }
}

@media (min-width: 1000px) {
}
@media (min-width: 766px) and (max-width: 999px) {
}
@media (min-width: 640px) and (max-width: 765px) {
  .nav__container {
    background-color: #fffefc;
    .nav__list {
      .nav__link {
        color: var(--text-color);
      }
    }
  }
}
@media (max-width: 639px) {
  .nav__container {
    height: 60px;
    border-bottom: solid 1px var(--border-color);
    background-color: #fffefc;
  }
  .nav__routes {
    display: none;
  }
  .mobile__nav.open {
    display: block;
  }
  .nav__burger {
    display: block;
    svg {
      height: 30px;
      cursor: pointer;
    }
  }
  .nav__container {
    background-color: #fffefc;
    .nav__list {
      .nav__link {
        color: var(--text-color);
      }
    }
  }
}
</style>
