<template>
  <div class="container-main">
    <div class="section">
      <div class="left">
        <h1>Cumplamos con los objetivos de tu empresa</h1>
        <p>
          Si quieres conocer nuestra solución y ver el funcionamiento de nuestra
          plataforma, registra tus datos que nos pondremos en contacto contigo a
          la brevedad.
        </p>

        <span class="contact-info">
          <span class="social-networks">
            <a href="https://www.facebook.com/profile.php?id=100076061235857">
              <img src="../assets/logos/facebook-logo.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/elhadigital/">
              <img src="../assets/logos/instagram-logo.svg" alt="" />
            </a>
            <a href="https://www.linkedin.com/company/elhadigital/">
              <img src="../assets/logos/linkedin-logo.svg" alt="" />
            </a>
            <p>
              <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm90.7 96.7c9.7-2.6 19.9 2.3 23.7 11.6l20 48c3.4 8.2 1 17.6-5.8 23.2L168 231.7c16.6 35.2 45.1 63.7 80.3 80.3l20.2-24.7c5.6-6.8 15-9.2 23.2-5.8l48 20c9.3 3.9 14.2 14 11.6 23.7l-12 44C336.9 378 329 384 320 384C196.3 384 96 283.7 96 160c0-9 6-16.9 14.7-19.3l44-12z"
                />
              </svg>
              +51 984126441
            </p>
          </span>
        </span>
      </div>
      <div class="form">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <Input
            label="Nombre"
            :value="name"
            type="text"
            id="name"
            name="name"
            placeholder="Nombre"
            :required="true"
          />
          <Input
            label="Correo electronico"
            :value="email"
            type="text"
            id="email"
            name="email"
            placeholder="nombre@elha.com"
            :required="true"
          />
          <Input
            label="Compañia"
            :value="company"
            type="text"
            id="company"
            name="company"
            placeholder="elha digital"
            :required="true"
          />
          <text-area
            label="Mensaje"
            :value="message"
            id="message"
            name="message"
            :required="true"
            placeholder="¡Hola! Me gustaría contactarme con un asesor para conocer más de..."
          />
          <Button type="submit" size="lg">Enviar</Button>
        </form>
      </div>
    </div>
    <hr class="separator" />
  </div>
</template>

<script>
import Input from '../components/Input.vue';
import TextArea from '../components/TextArea.vue';
import Button from '../components/Button.vue';

export default {
  components: {
    Input,
    Button,
    TextArea,
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  margin-top: 120px;
}

h1 {
  font-size: 3.15rem;
  line-height: 4rem;
  text-align: left;
}

.left {
  display: flex;
  flex-flow: column;
  text-align: left;
  gap: 1rem;

  .contact-info {
    p {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      display: grid;
      place-items: center;
      width: 26px;
      fill: #3a3a3a;
    }
  }
}

.section {
  display: grid;
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  & > * {
    width: calc(50% - 2.1rem);
  }
}

form {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.social-networks {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  img {
    height: 26px;
  }
}

@media (max-width: 950px) {
  .section {
    flex-flow: column;
    gap: 3rem;
    & > * {
      width: 100%;
    }
  }
  .container-main {
    margin-top: 50px;
  }
  h1 {
    line-height: 3.4rem;
  }
}
</style>
