<template>
  <div class="container-main">
    <div class="section">
      <div>
        <h1>
          Soluciones
          <span style="color: var(--primary-color)">digitales</span> en recursos
          humanos
        </h1>
        <h6>
          Acerca a tus colaboradores a las estrategias de tu organización. Con
          nuestras plataformas digitales mejoras la gestión y te enfocas en el
          core del negocio.
        </h6>
        <span>
          <Button variant="primary" size="lg" to="/contactanos">
            Contactanos
          </Button>
        </span>
      </div>
      <figure></figure>
    </div>

    <div class="clients-logos">
      <h5>Trusted by teams at</h5>
      <carousel
        class="carousel"
        :perPage="carouselPerPage"
        :navigationEnabled="true"
        :paginationEnabled="false"
      >
        <slide v-for="index in 15" :key="index">
          <figure>
            <img :src="require(`../assets/logos/logo${index}.png`)" alt="" />
          </figure>
        </slide>
      </carousel>
    </div>

    <div class="section section__solutions">
      <span>
        <h2>Nuestras soluciones</h2>
        <p>Unimos BIG Data con la gestión de Recursos Humanos</p>
      </span>
      <div>
        <span>
          <figure>
            <img src="../assets/clima-main.png" alt="" />
          </figure>
        </span>
        <div>
          <h3>Clima laboral</h3>
          <p>
            Con nuestro dinámico dashboard podrás obtener resultados a tiempo
            real, de manera rápida, flexible, ágil y segura. Además podrás
            descargar todos los reportes que necesites para analizar la
            información a la medida de tu organización.
          </p>

          <Button variant="secondary" size="lg" to="/clima-laboral"
            >Ver mas →</Button
          >
        </div>
      </div>
    </div>

    <div class="section section__solutions">
      <div>
        <div>
          <h3>Valoración de Puestos y Análisis Salarial</h3>
          <p>
            Identifica la equidad de genero en tu organización. Asimismo, la
            plataforma te permitirá analizar de manera adecuada la brecha
            salarial de manera general y específica de toda la organización.
          </p>

          <Button variant="secondary" size="lg" to="/evaluacion">
            Ver mas →
          </Button>
        </div>

        <span class="double-image">
          <figure>
            <img src="../assets/evaluacion-main.png" alt="" />
          </figure>

          <figure>
            <img src="../assets/evaluacion-main2.png" alt="" />
          </figure>
        </span>
      </div>
    </div>

    <hr class="separator" />

    <div class="section section__testimonies">
      <span>
        <h2>Testimonios</h2>
        <p>Lo que dicen de nosotros nuestros clientes</p>
      </span>
      <div>
        <carousel
          class="carousel"
          :perPage="TestimonyCarouselPerPage"
          :navigationEnabled="false"
          :paginationEnabled="true"
          paginationActiveColor="#48B8AB"
        >
          <slide v-for="(item, index) in cardItems" :key="index">
            <Card :item="item" />
          </slide>
        </carousel>
      </div>
    </div>

    <hr class="separator" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Card from '@/components/Card.vue';
import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
    Button,
    Carousel,
    Slide,
    Card,
  },
  data() {
    return {
      cardItems: [
        {
          img: 'logo14',
          text: `“La herramienta es muy ágil, nos ha permitido tener resultados en vivo a través del dashboard; además, la flexibilidad de la herramienta para combinar datos facilitó el análisis de la información y nos dio data más detallada para nuestro informe final. En líneas generales la herramienta es muy buena, cumple con los requisitos para poder realizar un buen estudio de clima laboral y definitivamente la recomendaremos a nuestros contactos.`,
          name: 'Renato Cabrera',
          company: 'Grupo Pakatnamu',
        },
        {
          img: 'logo13',
          text: `“Estamos muy contentos con la herramienta. La plataforma es intuitiva y fácil de usar para el administrador como para los trabajadores. Conectarse desde cualquier dispositivo móvil permite una rapidez que pocas veces encontré en el mercado. Además, el soporte técnico que brinda el equipo de ELHA es de primera calidad. Excelente plataforma.`,
          name: 'Daniel Leanos',
          company: 'TOYOSA',
        },
        {
          img: 'logo16',
          text: `“La Plataforma muy amigable, brinda resultados en tiempo real, y nos da la posibilidad de hacer seguimiento, siempre con la confidencialidad real de los resultados. Asimismo, nos ahorra mucho tiempo en la tabulación, seguimiento y control. La recomiendo por la posibilidad de automatizar el proceso y dado que es muy amigable, comprensible y customizable, puede adaptarse a las necesidades de medición de cualquier empresa.`,
          name: 'Manuel Perez Gil',
          company: 'Suiza Lab',
        },
        {
          img: 'logo1',
          text: `“Valoro mucho la sencillez y flexibilidad de la plataforma, se adecua a nuestra necesidad para la medición de clima laboral. Otro valor importante es el de obtener los resultados en tiempo real, permitiendo este punto trabajar en planes de mejoras mucho más rápidos. Recomiendo la plataforma por su flexibilidad, facilita enormemente el trabajo y elimina la carga operativa manual.`,
          name: 'Eva Fariña',
          company: 'Regional Seguros',
        },
        {
          img: 'logo8',
          text: `“Es una plataforma dinámica y amigable, permite que el manejo de la herramienta sea práctica. Recomiendo a ELHA por ser de fácil acceso y uso para colaboradores y la empresa.`,
          name: 'Fiorella Crisolo',
          company: 'Mornese',
        },
        {
          img: 'logo15',
          text: `“Recomiendo la plataforma ya que permite que los usuarios respondan desde cualquier dispositivo, de forma muy amigable incluso para aquellos que no son tan afines a la tecnología, garantizando asimismo la confidencialidad. A su vez, los técnicos brindan un excelente soporte que permite a la organización utilizar la plataforma sin dificultades. Fácil de usar, y soporte ágil y dinámico, 100% recomendable.`,
          name: 'Paola Rossini',
          company: 'Torrevieja',
        },
      ],
    };
  },

  computed: {
    carouselPerPage() {
      if (window.innerWidth >= 1000) {
        return 7;
      } else if (window.innerWidth >= 766) {
        return 5;
      } else if (window.innerWidth >= 640) {
        return 4;
      } else {
        return 2;
      }
    },
    TestimonyCarouselPerPage() {
      if (window.innerWidth >= 1300) {
        return 3;
      } else if (window.innerWidth >= 880) {
        return 2;
      } else {
        return 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  .section:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 360px;
    & > div:first-child {
      display: flex;
      flex-flow: column;
      width: 44%;
      text-align: left;
      gap: 2rem;
      overflow: hidden;
    }
    figure {
      border-bottom-left-radius: 100px;
      position: absolute;
      top: 0;
      right: -6vw;
      background-image: url(../assets/nosotros.jpeg);
      background-size: cover;
      height: 875px;
      width: 63%;
      z-index: -100;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/main-slider-mask.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: auto;
      }
    }
  }

  .section {
    margin-top: 120px;

    &__solutions {
      & > div {
        margin-top: 3rem;
        width: 100%;
        display: flex;
        gap: 3rem;
        span {
          width: 67%;
          figure,
          img {
            width: 100%;
          }
          figure {
            box-shadow: 0px 36px 89px rgb(0 0 0 / 4%),
              0px 23.3333px 52.1227px rgb(0 0 0 / 3%),
              0px 13.8667px 28.3481px rgb(0 0 0 / 2%),
              0px 7.2px 14.4625px rgb(0 0 0 / 2%),
              0px 2.93333px 7.25185px rgb(0 0 0 / 2%),
              0px 0.666667px 3.50231px rgb(0 0 0 / 1%);
          }
          &.double-image {
            height: 481px;
            position: relative;
            figure {
              width: 65%;
              &:first-child {
                position: absolute;
                top: 0;
              }
              &:nth-child(2) {
                position: absolute;
                bottom: 0;
                right: 0;
              }
            }
          }
        }
        div {
          width: 33%;
          display: flex;
          gap: 0.5rem;
          flex-flow: column;
          text-align: left;
          justify-content: center;
          p {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    &__testimonies {
      & > div {
        margin-top: 3rem;
      }
    }
  }

  .clients-logos .carousel {
    margin: 0 1.5rem;
    figure {
      display: grid;
      place-items: center;
      height: 100%;
      width: 100%;
      padding: 1rem 0;
      margin: auto;
      img {
        height: 30px;
        // filter: grayscale(100) opacity(0.7);
      }
    }
  }
}

@media (min-width: 1000px) {
}
@media (min-width: 766px) and (max-width: 999px) {
}
@media (min-width: 640px) and (max-width: 765px) {
  .container-main {
    .section {
      margin-top: 30px;
    }
    .section__solutions > div {
      flex-flow: column-reverse;
      gap: 2rem;
      div {
        width: 100%;
      }
      span {
        width: 100%;
      }
    }
    .section:first-child {
      margin-bottom: 90px;
      figure {
        height: 464px;
        &:before {
          background-size: cover;
          background-position: left bottom;
        }
      }
      & > div:first-child {
        gap: 1.5rem;
      }
    }
  }
}
@media (max-width: 639px) {
  .container-main {
    h1 {
      color: #fffefc !important;
      * {
        color: #fffefc !important;
      }
    }
    h6 {
      color: #fffefc !important;
      font-weight: 400;
    }
    .section {
      margin-top: 30px;
    }
    .section__solutions > div {
      flex-flow: column-reverse;
      gap: 2rem;
      div {
        width: 100%;
      }
      span {
        width: 100%;
      }
    }
    .section:first-child {
      * {
        text-align: center;
      }
      & > div:first-child {
        gap: 1.5rem;
        width: 100%;
      }
      height: 384px;
      max-width: 328px;
      width: 100%;
      margin: auto;
      padding-bottom: 50px;
      margin-top: 80px;
      figure {
        z-index: -1000;
        height: 544px;
        width: 100vw;
        &:before {
          z-index: 15;
          background-size: cover;
          background-position: left bottom;
          background-position: -60px bottom;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
