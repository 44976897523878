<template>
  <router-link v-if="to" :to="to" :class="[btnVariant, btnSize]" class="btn">
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </router-link>

  <a
    v-else-if="href"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    :class="[btnVariant, btnSize]"
    class="btn"
  >
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </a>

  <button
    v-else
    @click="$emit('click')"
    :type="type"
    :disabled="disabled"
    :class="[btnVariant, btnSize]"
    class="btn"
  >
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    to: String,
    href: String,
    variant: String,
    type: String,
    disabled: Boolean,
    size: String,
  },
  computed: {
    btnVariant() {
      switch (this.variant) {
        case 'blue':
          return 'btn--blue';
        case 'secondary':
          return 'btn--secondary';
        default:
          return 'btn--primary';
      }
    },

    btnSize() {
      switch (this.size) {
        case 'lg':
          return 'btn--lg';
        case 'md':
          return 'btn--md';
        default:
          return 'btn--sm';
      }
    },
  },
};
</script>

<style scoped>
.btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.75rem 1.5em;
  font-size: 1rem;
  font-weight: 570;
  border-radius: 5px;
  transition: transform 300ms;
  border: none;
  width: fit-content;
}
.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.btn:active:not(:disabled) {
  transform: translateY(1px);
}
.btn--primary {
  color: white;
  background-color: var(--primary-color);
  transition: background-color 300ms;
}

.btn--secondary {
  transition: background-color 300ms;
  border: solid 1px #bababa;
  background-color: white;
  font-weight: 570;
}

.btn--blue {
  color: white;
  background-color: var(--secondary-color);
  transition: background-color 300ms;
}
.btn--blue:hover:not(:disabled) {
  background-color: #1478ca;
}

.btn--primary:hover:not(:disabled) {
  background-color: #3b968b;
}
.btn--secondary:hover:not(:disabled) {
  background-color: rgba(0,0,0,0.03);
}

.btn--sm {
  height: 28px;
}

.btn--md {
  height: 30px;
  font-size: 0.84375rem;
  padding: 0.25rem 0.6875rem;
  line-height: 1.1875rem;
}

.btn--lg {
  height: 36px;
}
</style>
