<template>
  <div :name="name || label" class="input">
    <label v-if="label" :for="id" class="label">
      <span>{{ label || name }}</span>
      <span>{{ required ? ' *' : '' }}</span>
    </label>
    <input
      :id="id"
      :value="value"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      class="field"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomInput',

  props: {
    value: {
      default: '',
      type: String,
    },

    name: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    label: String,
    placeholder: String,
    id: String,
    required: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;
}

.label {
  line-height: 100%;
  display: flex;
  margin-bottom: 5px;
  span {
    font-size: 0.875rem;
    color: var(--text-color-3);
    font-weight: 400;
  }
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--primary-color);
}

.label--invalid,
.error {
  color: red;
}

.error {
  margin-top: 2em;
  font-size: 0.75rem;
}

.field {
  height: 36px;
  width: 100%;
  padding: 4px 10px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  transition: border-color 300ms;
  font-size: 0.9375rem;
  &::placeholder {
    color: #999999a1;
    font-weight: 0;
  }
}

.field:not(.field--invalid):focus {
  border-color: var(--primary-color);
  outline: none;
}

.field--invalid {
  border-color: red;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}
</style>
