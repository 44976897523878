<template>
  <div class="container-main" :class="{ overflowHidden: !hide }">
    <div class="section">
      <div>
        <h1>
          Un clima laboral
          <span style="color: var(--primary-color)">óptimo</span> para un
          desempeño <span style="color: var(--primary-color)">óptimo</span>
        </h1>
        <h6>
          Te presentamos nuestra plataforma de medición de clima laboral. Un
          solución a la medida de tu organización.
        </h6>
        <span>
          <Button variant="primary" size="lg" @click="redirect">
            Iniciar sesion
          </Button>
          <Button variant="secondary" size="lg" @click="playVideo">
            Ver video ▶
          </Button>
        </span>
      </div>

      <figure></figure>
    </div>
    <div class="section section__steps">
      <span>
        <h2>Clima laboral</h2>
        <p>
          Con nuestra plataforma, estructura tu encuesta de manera rápida, ágil
          y segura.
        </p>
      </span>
      <div>
        <h2><span>1</span> Elige tus dimensiones</h2>
        <p>
          Puedes elegir las mejores dimensiones y preguntas para tu
          organización. Crear, eliminar, editar y gestionar de manera adecuada
          tu encuesta de clima laboral.
        </p>
        <figure>
          <img src="../assets/clima__step1.png" alt="" />
        </figure>
      </div>
    </div>
    <div class="section section__steps">
      <div>
        <h2><span>2</span> Elige tu escala</h2>
        <p>
          Puedes elegir la escala que se adecue a los objetivos de tu
          organización. La presición en las respuestas de tus encuestas, te
          brindará mejores resultados.
        </p>
        <figure>
          <img src="../assets/clima__step2.png" alt="" />
        </figure>
      </div>
    </div>
    <div class="section section__steps">
      <div>
        <h2><span>3</span> Carga a tus empleados</h2>
        <p>
          Nuestra plataforma permite administrar y registrar información de tus
          trabajadores al mínimo detalle lo que permitirá segmentar los
          resultados que verás reflejados en nuestro dinamico dashboard.
        </p>
        <figure>
          <img src="../assets/clima__step3.png" alt="" />
        </figure>
      </div>
    </div>
    <div class="section section__steps">
      <div>
        <h2><span>4</span> Visualiza los resultados</h2>
        <p>
          BIG DATA + RECURSOS HUMANOS. Con nuestra plataforma podrás obtener los
          resultados en tiempo real lo que permitirá identificar de las
          oportunidades de mejora para tu organización.
        </p>
        <figure>
          <img src="../assets/clima-main.png" alt="" />
        </figure>
      </div>
    </div>
    <div class="section"></div>
    <hr class="separator" />

    <div class="modal" :class="{ hide: hide }">
      <div class="content">
        <div class="menu">
          <span
            @click="
              hide = true;
              $refs.video.pause();
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path
                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
              />
            </svg>
          </span>
        </div>
        <video controls ref="video" @ended="hide = true">
          <source
            src="https://www.elhadigital.com/media/clima-video.0730c506.mp4#t=0.1"
            type="video/mp4"
            preload="metadata"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      hide: true,
    };
  },
  methods: {
    redirect() {
      window.location.replace('https://climalaboral.elhadigital.com');
    },
    playVideo() {
      this.$refs.video.play();
      this.hide = false;
      if (window.matchMedia('(max-width: 700px)').matches) {
        this.$refs.video.requestFullscreen();
      } else {
        this.hide = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  &.overflowHidden {
    overflow: hidden;
  }
  .section:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 300px;
    & > div:first-child {
      display: flex;
      flex-flow: column;
      width: 41%;
      text-align: left;
      gap: 2rem;
    }
    .thumbnail {
      width: 562px;
      top: 0;
      position: relative;
      svg {
        height: 5rem;
        width: 5rem;
        fill: #fffefc;
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      display: grid;
      place-items: center;
    }
    figure {
      border-bottom-left-radius: 100px;
      position: absolute;
      top: 0;
      right: -6vw;
      background-image: url(../assets/thumbnail.jpeg);
      background-size: cover;
      height: 875px;
      width: 63%; 
      z-index: -100;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/main-slider-mask.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: auto;   
      }
    }
    h6 + span {
      display: flex;
      gap: 1.5rem;
    }
  }

  .section {
    margin-top: 120px;
    &__steps div {
      margin: auto;
      margin-top: 3rem;
      max-width: 940px;
      width: 100%;
      text-align: left;
      h2 {
        font-size: 2rem;
        span {
          font-size: 5.3rem;
        }
      }
      figure {
        margin-top: 1.5rem;
        background-color: white;
        img {
          width: 100%;
          box-shadow: 0px 36px 89px rgb(0 0 0 / 4%),
            0px 23.3333px 52.1227px rgb(0 0 0 / 3%),
            0px 13.8667px 28.3481px rgb(0 0 0 / 2%),
            0px 7.2px 14.4625px rgb(0 0 0 / 2%),
            0px 2.93333px 7.25185px rgb(0 0 0 / 2%),
            0px 0.666667px 3.50231px rgb(0 0 0 / 1%);
        }
      }
    }
  }
}

.modal {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em 1.2em;
    text-align: left;
    box-shadow: 0px 87px 57px -66px rgba(0, 0, 0, 0.541) inset;
    span {
      height: 2.5em;
      width: 2.5em;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    svg {
      height: 4rem;
      fill: #fffefc;
      cursor: pointer;
    }
  }
  video {
    border-radius: 8px;
    width: 73%;
  }
}
.hide {
  display: none;
}

@media (min-width: 1000px) {
}
@media (min-width: 766px) and (max-width: 999px) {
}
@media (min-width: 640px) and (max-width: 765px) {
  .container-main {
    .section {
      margin-top: 30px;
    }
    .section:first-child {
      margin-bottom: 90px;
      figure {
        height: 464px;
        &:before {
          background-size: cover;
          background-position: left bottom;
        }
      }
      & > div:first-child {
        gap: 1.5rem;
      }
    }
  }
}
@media (max-width: 639px) {
  .container-main {
    h1 {
      color: #fffefc !important;
      * {
        color: #fffefc !important;
      }
    }
    .section__steps div h2 {
      font-size: 1.7rem !important;
      line-height: 4rem;
      span {
        font-size: 3.5rem !important;
      }
    }
    h6 {
      color: #fffefc !important;
      font-weight: 400;
    }
    .section {
      margin-top: 30px;
    }
    .section:first-child {
      * {
        text-align: center;
      }
      & > div:first-child {
        gap: 1.5rem;
        width: 100%;
      }
      height: 384px;
      max-width: 328px;
      width: 100%;
      margin: auto;
      padding-bottom: 50px;
      margin-top: 80px;
      figure {
        z-index: -1000;
        height: 544px;
        width: 100vw;
        &:before {
          z-index: 15;
          background-size: cover;
          background-position: left bottom;
          background-position: -60px bottom;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
