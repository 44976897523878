<template>
  <div :name="name || label" class="textarea">
    <label v-if="label" :for="id" class="label">
      <span>{{ label || name }}</span>
      <span>{{ required ? ' *' : '' }}</span>
    </label>
    <textarea
      :value="value"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      class="field"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'CustomTextarea',

  props: {
    value: {
      default: '',
      type: String,
    },

    name: {
      type: String,
      default: '',
    },

    required: {
      default: false,
    },

    label: String,
    placeholder: String,
    id: String,
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;
}

.label {
  line-height: 100%;
  display: flex;
  margin-bottom: 5px;
  span {
    font-size: 0.875rem;
    color: var(--text-color-3);
    font-weight: 400;
  }
}

.label--invalid,
.error {
  color: red;
}

.error {
  margin-top: 1em;
  font-size: 0.75rem;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--primary-color);
}

.field {
  min-height: 80px;
  width: 100%;
  padding: 4px 10px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  transition: border-color 300ms;
  font-size: 0.9375rem;
  font-family: inherit;
  font-weight: 400;
  &::placeholder {
    color: #999999a1;
    font-weight: 0;
  }
}

.field:not(.field--invalid):focus {
  border-color: var(--primary-color);
  outline: none;
}

.field--invalid {
  border-color: red;
}
</style>
