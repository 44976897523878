<template>
  <div class="container">
    <div class="card">
      <figure>
        <img :src="require(`../assets/logos/${item.img}.png`)" alt="" />
      </figure>
      <p class="text">
        {{ item.text }}
      </p>
      <span>
        <p class="name">{{ item.name }}</p>
        <p class="company">{{ item.company }}</p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  place-items: center;
}
.card {
  background-color: #fffefc;
  text-align: left;
  display: flex;
  flex-flow: column;
  border: solid 1px var(--border-color);
  border-radius: 5px;
  width: 360px;
  height: 440px;
  margin: auto;
  display: grid;
  place-items: center;
  padding: 1.5rem 0;
  text-align: justify;
  text-justify: inter-word;
  figure {
    width: 300px;
    img {
      height: 30px;
    }
  }

  .text {
    font-weight: 500;
    height: 260px;
    width: 300px;
    overflow: hidden;
  }

  span {
    width: 300px;
  }

  .name,
  .company {
    font-size: 0.875rem;
  }
}

@media (max-width: 639px) {
  .card {
    width: 300px;

    .text,
    span,
    figure {
      width: 240px;
    }
    .text {
      height: 290px;
    }
  }
}
</style>
