<template>
  <div class="container-main" :class="{ overflowHidden: !hide }">
    <div class="section">
      <div>
        <h1>
          <span style="color: var(--secondary-color)">Valoración</span> de
          Puestos y
          <span style="color: var(--secondary-color)">Análisis</span> Salarial
        </h1>
        <h6>
          Te presentamos una plataforma innovadora donde podrás analizar la
          equidad salarial, equidad de género, la brecha salarial y valorizar
          cada puesto en tu organización.
        </h6>
        <span>
          <Button variant="blue" size="lg" @click="redirect">
            Iniciar sesion
          </Button>
          <Button variant="secondary" size="lg" @click="playVideo">
            Ver video ▶
          </Button>
        </span>
      </div>

      <figure></figure>
    </div>
    <div class="section section__view">
      <span>
        <h2>Valoración de Puestos y Análisis Salarial</h2>
        <p>
          Lorem ipsum dolor sit amet omnis dolor architecto officiis, eligendi
          culpa qui nulla aut.
        </p>
      </span>
    </div>

    <div class="section section__view section__view--people">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="user-circle"
        >
          <path
            fill="#0092E4"
            d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
          ></path>
        </svg>
        <h3>Personas y puestos</h3>
      </div>
      <div>
        <div>
          <figure>
            <img src="../assets/valoracion__empleados.png" alt="" />
          </figure>
          <div>
            <h4>Empleados</h4>
            <p>
              Administra la información de tus empleados de acuerdo a las
              necesidades de tu organización.
            </p>
          </div>
        </div>
        <div>
          <figure>
            <img src="../assets/valoracion__agregar.png" alt="" />
          </figure>
          <div>
            <h4>Agregar / Editar empleados</h4>
            <p>
              Agrega, edita y modifica toda la información que necesites para
              visualizar tus resultados de manera óptima.
            </p>
          </div>
        </div>
        <div>
          <figure>
            <img src="../assets/valoracion__categorias.png" alt="" />
          </figure>
          <div>
            <h4>Categorías</h4>
            <p>
              Define, ingresa y carga todas las categorías que necesites para
              obtener resultados precisos.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section section__view">
      <div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              id="balance-scale"
            >
              <path
                fill="#FAA700"
                d="M22.96423,13.82263a.94762.94762,0,0,0-.02819-.17419L20.63135,7.51135A2.99558,2.99558,0,0,0,22,5a1,1,0,0,0-2,0,1.00037,1.00037,0,0,1-1.88184.47266A2.8934,2.8934,0,0,0,15.54,4H13V3a1,1,0,0,0-2,0V4H8.46A2.8934,2.8934,0,0,0,5.88184,5.47266,1.00037,1.00037,0,0,1,4,5,1,1,0,0,0,2,5,2.99558,2.99558,0,0,0,3.36865,7.51135L1.064,13.64844a.94762.94762,0,0,0-.02819.17419A.94855.94855,0,0,0,1,14c0,.00928.00269.01782.00275.0271.0003.01318.003.02533.0039.03845a3.99379,3.99379,0,0,0,7.9867,0c.00085-.01312.0036-.02527.0039-.03845C8.99731,14.01782,9,14.00928,9,14a.94855.94855,0,0,0-.03577-.17737.94762.94762,0,0,0-.02819-.17419L6.62866,7.50421A2.98961,2.98961,0,0,0,7.64258,6.41992.917.917,0,0,1,8.46,6H11V20H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2H13V6h2.54a.917.917,0,0,1,.81738.41992,2.98961,2.98961,0,0,0,1.01392,1.08429L15.064,13.64844a.94762.94762,0,0,0-.02819.17419A.94855.94855,0,0,0,15,14c0,.00928.00269.01782.00275.0271.0003.01318.003.02533.0039.03845a3.99379,3.99379,0,0,0,7.9867,0c.00085-.01312.0036-.02527.0039-.03845C22.99731,14.01782,23,14.00928,23,14A.94855.94855,0,0,0,22.96423,13.82263ZM5,8.85553,6.5564,13H3.4436ZM6.72266,15A2.02306,2.02306,0,0,1,5,16a2.00023,2.00023,0,0,1-1.73145-1ZM19,8.85553,20.5564,13H17.4436ZM19,16a2.00023,2.00023,0,0,1-1.73145-1h3.45411A2.02306,2.02306,0,0,1,19,16Z"
              ></path>
            </svg>
            <h2>Equidad de género</h2>
          </span>
          <p>
            Con nuestra plataforma podrás identificar la equidad de género en
            toda la organización; del mismo modo podrás revisar la equidad de
            género en los distintos niveles de la empresa, en lo líderes que la
            integran, en las distintas posiciones y sobre todo crear las
            categorías que desees para obtener esta información que ayuden a
            fortalecer las decisiones de la organización.
          </p>
        </div>
        <figure>
          <img src="../assets/valoracion__equidad.png" alt="" />
        </figure>
      </div>
    </div>

    <div class="section section__view">
      <div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="money-bill"
            >
              <path
                fill="#48B8AB"
                d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm12,0a1,1,0,1,0,1,1A1,1,0,0,0,18,11Zm2-6H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V8A3,3,0,0,0,20,5Zm1,11a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H20a1,1,0,0,1,1,1ZM12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"
              ></path>
            </svg>
            <h2>Brecha salarial</h2>
          </span>
          <p>
            Puedes elegir las mejores dimensiones y preguntas para tu
            organización. Crear, eliminar, editar y gestionar de manera adecuada
            tu encuesta de clima laboral.
          </p>
          <div class="view__tabs">
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': viewsTabs.brecha }"
              @click="viewsTabs.brecha = true"
            >
              Board
            </span>
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': !viewsTabs.brecha }"
              @click="viewsTabs.brecha = false"
            >
              Dashboard
            </span>
          </div>
        </div>
        <figure>
          <img src="../assets/valoracion__brecha.png" alt="" />
        </figure>
      </div>
    </div>

    <div class="section section__view section__view--steps">
      <h2>Valorización de puestos</h2>
      <div>
        <div
          v-for="(step, index) in steps"
          :key="step.id"
          @click="selectedStep = step.id"
          class="view__step"
          :class="{ 'view__step--selected': selectedStep === step.id }"
        >
          <div class="view__step--svg" v-html="step.svg" />
          <span>{{ index + 1 }}. {{ step.name }}</span>
        </div>
      </div>
      <figure>
        <img :src="imageSrc" alt="" />
      </figure>
    </div>

    <div class="section section__view">
      <div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              id="sitemap"
            >
              <path
                fill="#EA4E43"
                d="M22,15H20V12a1,1,0,0,0-1-1H13V9h2a1,1,0,0,0,1-1V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V8A1,1,0,0,0,9,9h2v2H5a1,1,0,0,0-1,1v3H2a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H6V13H18v2H16a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16A1,1,0,0,0,22,15ZM7,17v4H3V17ZM10,7V3h4V7ZM21,21H17V17h4Z"
              ></path>
            </svg>
            <h2>Estructura Salarial Interna</h2>
          </span>
          <p>
            Con nuestra plataforma podrás diseñar y mantener de manera
            equitativa la estructura salarial interna en tu organización.
            Analiza y revisa cada puesto de trabajo de manera justa; elabora un
            análisis salarial eficiente para contribuir con los objetivos de tu
            empresa.
          </p>
          <div class="view__tabs">
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': viewsTabs.interna }"
              @click="viewsTabs.interna = true"
            >
              Board
            </span>
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': !viewsTabs.interna }"
              @click="viewsTabs.interna = false"
            >
              Dashboard
            </span>
          </div>
        </div>
        <figure>
          <img src="../assets/valoracion__interna.png" alt="" />
        </figure>
      </div>
    </div>

    <div class="section section__view">
      <div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="globe"
            >
              <path
                fill="#0092E4"
                d="M21.41,8.64s0,0,0-.05a10,10,0,0,0-18.78,0s0,0,0,.05a9.86,9.86,0,0,0,0,6.72s0,0,0,.05a10,10,0,0,0,18.78,0s0,0,0-.05a9.86,9.86,0,0,0,0-6.72ZM4.26,14a7.82,7.82,0,0,1,0-4H6.12a16.73,16.73,0,0,0,0,4Zm.82,2h1.4a12.15,12.15,0,0,0,1,2.57A8,8,0,0,1,5.08,16Zm1.4-8H5.08A8,8,0,0,1,7.45,5.43,12.15,12.15,0,0,0,6.48,8ZM11,19.7A6.34,6.34,0,0,1,8.57,16H11ZM11,14H8.14a14.36,14.36,0,0,1,0-4H11Zm0-6H8.57A6.34,6.34,0,0,1,11,4.3Zm7.92,0h-1.4a12.15,12.15,0,0,0-1-2.57A8,8,0,0,1,18.92,8ZM13,4.3A6.34,6.34,0,0,1,15.43,8H13Zm0,15.4V16h2.43A6.34,6.34,0,0,1,13,19.7ZM15.86,14H13V10h2.86a14.36,14.36,0,0,1,0,4Zm.69,4.57a12.15,12.15,0,0,0,1-2.57h1.4A8,8,0,0,1,16.55,18.57ZM19.74,14H17.88A16.16,16.16,0,0,0,18,12a16.28,16.28,0,0,0-.12-2h1.86a7.82,7.82,0,0,1,0,4Z"
              ></path>
            </svg>
            <h2>Estructura Salarial Externa</h2>
          </span>
          <p>
            La plataforma cuenta con un espacio donde podrás trabajar la
            estructura salarial externa de tu organización, identificar brechas
            salariales de acuerdo a las necesidades de la empresa. Con la
            información que tienes podrás trabajar de una forma rápida
            obteniendo resultados en tiempo real.
          </p>
          <div class="view__tabs">
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': viewsTabs.externa }"
              @click="viewsTabs.externa = true"
            >
              Board
            </span>
            <span
              class="view__tab"
              :class="{ 'view__tab--selected': !viewsTabs.externa }"
              @click="viewsTabs.externa = false"
            >
              Dashboard
            </span>
          </div>
        </div>
        <figure>
          <img src="../assets/valoracion__externa.png" alt="" />
        </figure>
      </div>
    </div>

    <div class="section section__view">
      <div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              id="folder-open"
            >
              <path
                fill="#FAA700"
                d="M22.78 10.37A1 1 0 0 0 22 10h-2V9a3 3 0 0 0-3-3h-6.28l-.32-1a3 3 0 0 0-2.84-2H4a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14.4a3 3 0 0 0 2.92-2.35L23 11.22a1 1 0 0 0-.22-.85ZM5.37 18.22a1 1 0 0 1-1 .78H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h3.56a1 1 0 0 1 1 .68l.54 1.64A1 1 0 0 0 10 8h7a1 1 0 0 1 1 1v1H8a1 1 0 0 0-1 .78Zm14 0a1 1 0 0 1-1 .78H7.21a1.42 1.42 0 0 0 .11-.35L8.8 12h12Z"
              ></path>
            </svg>
            <h2>Gestión de documentos</h2>
          </span>
          <p>
            Con nuestra plataforma podrás gestionar los perfiles, organigramas y
            más documentos de una manera ágil y segura, podrás enviar a todos
            tus trabajadores por correo electrónico desde nuestra herramienta,
            lo que permitirá establecer de manera trasversal una comunicación
            directa.
          </p>
        </div>
        <figure>
          <img src="../assets/valoracion__documentos.png" alt="" />
        </figure>
      </div>
    </div>
    <div class="section"></div>
    <hr class="separator" />

    <div class="modal" :class="{ hide: hide }">
      <div class="content">
        <div class="menu">
          <span
            @click="
              hide = true;
              $refs.video.pause();
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path
                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
              />
            </svg>
          </span>
        </div>
        <video controls ref="video" @ended="hide = true">
          <source
            src="https://www.elhadigital.com/media/clima-video.0730c506.mp4#t=0.1"
            type="video/mp4"
            preload="metadata"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      hide: true,
      selectedStep: 'factores',
      steps: [
        {
          name: 'Factores',
          id: 'factores',
          svg: `<svg style="height: 3rem" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="puzzle-piece"><path d="M17,22H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H6a4,4,0,0,1,7.3-2.18A3.86,3.86,0,0,1,14,6h3a1,1,0,0,1,1,1v3a4,4,0,0,1,2.18,7.3A3.86,3.86,0,0,1,18,18h0v3A1,1,0,0,1,17,22ZM5,8A1,1,0,0,0,4,9V19a1,1,0,0,0,1,1H16V16.82a1,1,0,0,1,.42-.82,1,1,0,0,1,.91-.13,1.77,1.77,0,0,0,1.74-.23A2,2,0,0,0,20,14.27a2,2,0,0,0-.48-1.59,1.89,1.89,0,0,0-2.17-.55,1,1,0,0,1-.91-.13,1,1,0,0,1-.42-.82V8H12.82a1,1,0,0,1-1-1.33,1.77,1.77,0,0,0-.23-1.74,1.94,1.94,0,0,0-3-.43A2,2,0,0,0,8,6a1.89,1.89,0,0,0,.13.67A1,1,0,0,1,7.18,8Z"></path></svg>`,
        },
        {
          name: 'Subfactores',
          id: 'subfactores',
          svg: `<svg style="height: 3rem" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="search-alt"><path d="M21.07,16.83,19,14.71a3.08,3.08,0,0,0-3.4-.57l-.9-.9a7,7,0,1,0-1.41,1.41l.89.89A3,3,0,0,0,14.71,19l2.12,2.12a3,3,0,0,0,4.24,0A3,3,0,0,0,21.07,16.83Zm-8.48-4.24a5,5,0,1,1,0-7.08A5,5,0,0,1,12.59,12.59Zm7.07,7.07a1,1,0,0,1-1.42,0l-2.12-2.12a1,1,0,0,1,0-1.42,1,1,0,0,1,1.42,0l2.12,2.12A1,1,0,0,1,19.66,19.66Z"></path></svg>`,
        },
        {
          name: 'Niveles',
          id: 'niveles',
          svg: `<svg style="height: 3rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="tachometer-fast"><path d="M15.29,10.29l-2.78,2.78A2.09,2.09,0,0,0,12,13a2,2,0,0,0-2,2,2.09,2.09,0,0,0,.07.51l-.78.78a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l.78-.78A2.09,2.09,0,0,0,12,17a2,2,0,0,0,2-2,2.09,2.09,0,0,0-.07-.51l2.78-2.78a1,1,0,0,0-1.42-1.42ZM12,4A10,10,0,0,0,2,14a9.91,9.91,0,0,0,1.69,5.56,1,1,0,0,0,1.66-1.12,8,8,0,1,1,13.3,0,1,1,0,0,0,.27,1.39,1,1,0,0,0,.56.17,1,1,0,0,0,.83-.44A9.91,9.91,0,0,0,22,14,10,10,0,0,0,12,4Z"></path></svg>`,
        },
        {
          name: 'Resumen',
          id: 'resumen',
          svg: `<svg style="height: 3rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="eye"><path d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"></path></svg>`,
        },
        {
          name: 'Valorización',
          id: 'valorizacion',
          svg: `<svg style="height: 3rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="suitcase"><path d="M19,6.5H16v-1a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3v-9A3,3,0,0,0,19,6.5Zm-9-1a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v1H10Zm10,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13.45H7V14.5a1,1,0,0,0,2,0V13.45h6V14.5a1,1,0,0,0,2,0V13.45h3Zm0-7H4V9.5a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Z"></path></svg>`,
        },
      ],
      viewsTabs: { brecha: true, interna: true, externa: true },
    };
  },
  methods: {
    redirect() {
      window.location.replace('https://climalaboral.elhadigital.com');
    },
    playVideo() {
      this.$refs.video.play();
      this.hide = false;
      if (window.matchMedia('(max-width: 700px)').matches) {
        this.$refs.video.requestFullscreen();
      } else {
        this.hide = false;
      }
    },
  },

  computed: {
    imageSrc() {
      return require(`../assets/valoracion__${this.selectedStep}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  &.overflowHidden {
    overflow: hidden;
  }
  .section:first-child {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 300px;
    & > div:first-child {
      display: flex;
      flex-flow: column;
      width: 41%;
      text-align: left;
      gap: 2rem;
    }
    .thumbnail {
      width: 562px;
      top: 0;
      position: relative;
      svg {
        height: 5rem;
        width: 5rem;
        fill: #fffefc;
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      display: grid;
      place-items: center;
    }
    figure {
      border-bottom-left-radius: 100px;
      position: absolute;
      top: 0;
      right: -6vw;
      background-image: url(../assets/thumbnail.jpeg);
      background-size: cover;
      height: 875px;
      width: 63%;
      z-index: -100;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/main-slider-mask.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: auto;
      }
    }
    h6 + span {
      display: flex;
      gap: 1.5rem;
    }
  }

  .section {
    &__view {
      &:not(.section__view--steps):not(.section__view--people) > div {
        background-color: var(--gray-color-2);
        border-radius: 12px;
        padding: 0 2rem;
        padding-top: 1.5rem;
        margin: auto;
        margin-top: 3rem;
        max-width: 1000px;
        width: 100%;
        text-align: left;
        display: flex;
        gap: 1.875rem;
        overflow: hidden;
        p {
          color: var(--text-color-3);
        }
        div {
          min-width: calc(36%);
          display: flex;
          flex-flow: column;
          gap: 0.5rem;
          span {
            display: flex;
            flex-flow: column;
            gap: 0.25rem;
            svg {
              height: 3rem;
              width: 3rem;
              min-height: 2rem;
              min-width: 2rem;
            }
            h2 {
              font-size: 2rem;
              line-height: 2.1rem;
            }
          }
        }
        figure {
          background-color: white;
          border-radius: 10px;
          img {
            border: solid 1px #e1e3e9;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 12px;
            width: 100%;
            width: 600px;
            box-shadow: 0px 36px 89px rgb(0 0 0 / 4%),
              0px 23.3333px 52.1227px rgb(0 0 0 / 3%),
              0px 13.8667px 28.3481px rgb(0 0 0 / 2%),
              0px 7.2px 14.4625px rgb(0 0 0 / 2%),
              0px 2.93333px 7.25185px rgb(0 0 0 / 2%),
              0px 0.666667px 3.50231px rgb(0 0 0 / 1%);
          }
        }
      }
      &--people {
        margin: auto;
        padding-top: 4rem;
        margin-top: 4rem;
        margin-bottom: 6rem;
        display: flex;
        max-width: 1000px;
        gap: 3rem;
        border-top: solid 2px var(--gray-color-1);
        h3 {
          line-height: 1.875rem;
        }
        & > div {
          &:first-child {
            min-width: 9rem;
            display: flex;
            flex-flow: column;
            gap: 0.25rem;
            svg {
              height: 3rem;
              width: 3rem;
            }
          }
          display: flex;
          gap: 1rem;
          text-align: left;
          div {
            display: flex;
            flex-flow: column;
            gap: 0.25rem;
            p {
              font-size: 0.875rem;
            }
          }
        }
        figure {
          background-color: white;
          border-radius: 10px;
          img {
            border-radius: 10px;
            height: 10rem;
          }
        }
      }
      &--steps {
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 6rem;
        max-width: 1000px;
        display: flex;
        flex-flow: column;
        gap: 2rem;
        & > div {
          background-color: white;
          display: flex;
          gap: 0.5rem;
          span {
            font-weight: 500;
          }
          .view__step {
            width: 100%;
            display: flex;
            flex-flow: column;
            background-color: var(--gray-color-1);
            border-radius: 16px;
            border: solid 3px var(--gray-color-1);
            padding: 1.2rem 0.75rem 1.325rem;
            cursor: pointer;
            &:not(.view__step--selected):hover {
              background-color: var(--gray-color-2);
            }
            &--svg {
              fill: var(--text-color);
            }
            &--selected {
              background-color: #fff;
              border: solid 3px var(--gray-color-2);
            }
          }
        }
        figure {
          background-color: white;
          border-radius: 10px;
          img {
            border: solid 1px #e1e3e9;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 12px;
            width: 100%;
            box-shadow: 0px 36px 89px rgb(0 0 0 / 4%),
              0px 23.3333px 52.1227px rgb(0 0 0 / 3%),
              0px 13.8667px 28.3481px rgb(0 0 0 / 2%),
              0px 7.2px 14.4625px rgb(0 0 0 / 2%),
              0px 2.93333px 7.25185px rgb(0 0 0 / 2%),
              0px 0.666667px 3.50231px rgb(0 0 0 / 1%);
          }
        }
      }
      .view__tabs {
        display: flex;
        flex-flow: row !important;
        .view__tab {
          margin-top: 0.5rem;
          width: fit-content;
          padding: 0.2rem 0.6rem 0.35rem 0.6rem;
          border-radius: 5px;
          border: 1px solid var(--gray-color-4);
          font-size: 0.875rem;
          cursor: pointer;
          &:not(.view__tab--selected):hover {
            background-color: var(--gray-color-3);
          }
          &--selected {
            background-color: var(--gray-color-4);
          }
        }
      }
    }
  }
}

.modal {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em 1.2em;
    text-align: left;
    box-shadow: 0px 87px 57px -66px rgba(0, 0, 0, 0.541) inset;
    span {
      height: 2.5em;
      width: 2.5em;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    svg {
      height: 3rem;
      fill: #fffefc;
      cursor: pointer;
    }
  }
  video {
    border-radius: 8px;
    width: 73%;
  }
}
.hide {
  display: none;
}

@media (min-width: 1130px) {
  .container-main {
    .section {
      &__view {
        &--people {
        }
      }
    }
  }
}
@media (min-width: 766px) and (max-width: 1130px) {
  .container-main {
    .section {
      &__view {
        &--people {
          flex-flow: column;
          figure img {
            width: 100%;
            height: inherit;
          }
        }
      }
    }
  }
}
@media (min-width: 640px) and (max-width: 766px) {
  .container-main {
    .section {
      margin-top: 30px;
      &__view {
        &--people {
          flex-flow: column;
          figure img {
            width: 100%;
            height: inherit;
          }
        }
      }
    }
    .section:first-child {
      margin-bottom: 90px;
      figure {
        height: 464px;
        &:before {
          background-size: cover;
          background-position: left bottom;
        }
      }
      & > div:first-child {
        gap: 1.5rem;
      }
    }
  }
}
@media (max-width: 640px) {
  .container-main {
    h1 {
      color: #fffefc !important;
      * {
        color: #fffefc !important;
      }
    }
    .section__view div h2 {
      font-size: 1.7rem !important;
      line-height: 4rem;
      span {
        font-size: 3.5rem !important;
      }
    }
    h6 {
      color: #fffefc !important;
      font-weight: 400;
    }
    .section {
      margin-top: 30px;
      &__view {
        &--people {
          flex-flow: column;
          figure img {
            width: 100%;
            height: inherit;
          }
        }
      }
      &__view {
        &:not(.section__view--steps):not(.section__view--people) {
          & > div {
            flex-flow: column;
            padding: 0 !important;
            & > div {
              padding: 0 2rem;
              padding-top: 1.5rem;
            }
          }
          figure {
            width: calc(100% - 2rem) !important;
            align-self: flex-end !important;
            img {
              width: 100% !important;
            }
          }
        }
        &--steps {
          & > div {
            justify-content: center;
            flex-wrap: wrap;
          }
        }
      }
      .view__step {
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        margin-top: 0.5rem;
        width: fit-content !important;
        padding: 0.2rem 0.6rem 0.35rem 0.6rem !important;
        border-radius: 5px !important;
        border: 1px solid white !important;
        font-size: 0.875rem !important;
        cursor: pointer;
        background-color: white !important;
        &--selected {
          background-color: var(--gray-color-1) !important;
        }
        &--svg {
          display: none;
        }
      }
    }
    .section:first-child {
      * {
        text-align: center;
      }
      & > div:first-child {
        gap: 1.5rem;
        width: 100%;
      }
      height: 384px;
      max-width: 328px;
      width: 100%;
      margin: auto;
      padding-bottom: 50px;
      margin-top: 80px;
      figure {
        z-index: -1000;
        height: 544px;
        width: 100vw;
        &:before {
          z-index: 15;
          background-size: cover;
          background-position: left bottom;
          background-position: -60px bottom;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
