import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import ContactUs from '../views/ContactUs.vue';
import ClimaLaboral from '../views/ClimaLaboral.vue';
import Evaluacion from '../views/Evaluacion.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/inicio',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contactanos',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/nosotros',
    name: 'About',
    component: About,
  },
  {
    path: '/clima-laboral',
    name: 'ClimaLaboral',
    component: ClimaLaboral,
  },
  {
    path: '/evaluacion',
    name: 'Evaluacion',
    component: Evaluacion,
  },
  //{ path: "*", redirect: "/" },
];

const router = new VueRouter({
  //mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
